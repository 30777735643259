<template lang="pug">
div(
    class="step-6"
    v-if="!!order"
)
    //- name bar
    TopBar
        OrderName(
            :order="order"
        )

    //- stoper
    div(
        style="background-color: var(--ion-color-accent); height: 300px"
        class="d-flex align-center justify-center"
    )
        IonText(
            color="white"
        )
            h1(
                style="font-size: 50px"
            ) 00:34:17

    //- jobs
    OrderLines(
        :order="order"
    )

    //- buttons
    div(class="buttons-height-1 mt-4")
        //- div(class="page-buttons-wrapper")
        //-     Container
        //-         IonRow(class="receding")
        //-             IonCol(
        //-                 size="12"
        //-             )
        //-                 IonButton(
        //-                     color="secondary"
        //-                     size="large"
        //-                     expand="block"
        //-                     @click="$router.push({ name: 'order-7', params: { id: order.id } })"
        //-                 )
        //-                     IonText(
        //-                         color="primary"
        //-                     )
        //-                         h3 Weiter zum Rapport
</template>

<script>
import TopBar from "@/components/sections/TopBar"
import OrderName from "@/components/molecules/OrderName"
import OrderLines from "@/components/molecules/OrderLines"
export default {
    components: {
        TopBar,
        OrderName,
        OrderLines
    },
    props: {
        order: {
            required: true
        }
    }
}
</script>